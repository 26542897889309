import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ElementsPage: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const { t } = useTranslation();
      const siteTitle = data.site.siteMetadata.title;
      const chefs = data.allStrapiChefs.nodes;
      return (
        <Layout title={siteTitle}>
          <SEO
            title={'Chefs'}
            description={'Listado de todos los chefs reconocidos y sus recetas'}
            keywords={['chefs', 'recetas']}
          />
          <div className="section-page-authors wrap">
            <div className="page-authors-wrap flex">
              <div className="page-authors-title">
                <h4>{t('CHEFS_PAGE_TITLE')}</h4>
              </div>
              {chefs.map((chef, index) => (
                <div
                  className={`${'page-author-wrap'} ${index % 2 === 0 ? 'is-profile-image' : 'no-profile-image'}  ${
                    index % 2 === 0 ? 'no-cover-image' : 'is-cover-image'
                  }`}
                  key={chef.id}
                >
                  <AniLink fade duration={1.2} to={chef.fields.path} className="item-link-overlay">
                    <div
                      className={`${index % 2 === 0 ? 'page-author-profile-image' : 'page-author-cover-image'}`}
                      style={{
                        backgroundImage: `url(${chef.avatar.childImageSharp.fluid.src})`,
                      }}
                    ></div>
                    <h2>{chef.alias}</h2>
                    <div className="page-author-social author-social">
                      <FontAwesomeIcon className="global-svg" icon={faInstagram} size="1x" />
                      <span>{chef.instagram}</span>
                    </div>
                    <p>{chef.description}</p>
                  </AniLink>
                </div>
              ))}
            </div>
          </div>
        </Layout>
      );
    }}
  />
);

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiChefs(sort: { fields: recipes, order: DESC }) {
      nodes {
        id
        alias
        instagram
        description
        avatar {
          childImageSharp {
            fluid {
              src
            }
          }
        }
        fields {
          path
        }
      }
    }
  }
`;

export default ElementsPage;
